<template>
    <section class="why-choose-wrap style1 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="wh-img-wrap">
                        <img src="../../assets/images/why-choose-us/wh-img-2.png" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Why Choose Us</span>
                            <h2>Get World Class &amp; Fastest Online Payment Service</h2>
                            <p>Local payment methods for global customers. Accept payments online, on any device, anywhere in the world. An all-in-one online payment solution built for your business.</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-graph"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Low Costing</h3>
                                    <p>Save time and resources while increasing flexibility with a complete payments solution.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-loan-1"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Safe &amp; Secure</h3>
                                    <p>Easily integrate NetWallet into your payments flow. Create a safe and seamless experience and let your customers pay with their preferred payment method.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-computer"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Live Support</h3>
                                    <p>
                                        Everything about payments in one place. Get answers to your questions, learn the way you want, become your own expert, and upskill your network.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>