<template>
    <div class="shopping-wrap ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="shopping-content">
                        <img src="../../assets/images/shopping/shopping-shape-1.png" alt="Image"
                            class="shopping-shape-one moveHorizontal">
                        <img src="../../assets/images/shopping/shopping-shape-2.png" alt="Image"
                            class="shopping-shape-two bounce">
                        <div class="content-title style1 ">
                            <span>Online Shopping</span>
                            <h2>Shop Online Make And Online Payments</h2>
                            <p>Start accepting online payment from your e-commerce channels now. Start receiving online
                                payment on your website, social medias or even chat apps! Get Paid Anytime Easily. Accept
                                payment anywhere. Online payment made easy.</p>
                        </div>
                        <ul class="content-feature-list list-style">
                            <li><i class="ri-check-double-line"></i>Accept Mobile Money Payments</li>
                            <li><i class="ri-check-double-line"></i>Accept Mobile Wallet Payments</li>
                            <li><i class="ri-check-double-line"></i>QR Code Payment for Retail Shops and Malls</li>
                        </ul>
                        <router-link to="/register" class="btn style1">Signup Today</router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="shopping-img-wrap">
                        <img src="../../assets/images/shopping/shopping-2.png" alt="Image">
                        <img src="../../assets/images/shopping/shopping-shape-3.png" alt="Image"
                            class="shopping-shape-three">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnlineShopping'
}
</script>