<template>
    <div class="testimonial-wrap ptb-100  bg-albastor">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <span>Our Testimonials</span>
                        <h2>What Our Client Says</h2>
                    </div>
                </div>
            </div>
            <div class="testimonial-slider-three">
                <carousel
                    :autoplay="5000"
                    :wrap-around="true"
                >
                    <Slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="testimonial-card style3">
                            <span class="quote-icon">
                                <i class="flaticon-quotation-mark"></i>
                            </span>
                            <p class="client-quote">{{slide.description}}</p>
                            <div class="testimonial-card-thumb">
                                <div class="client-info-wrap">
                                    <div class="client-img">
                                        <img :src="slide.image" alt="Image">
                                    </div>
                                    <div class="client-info">
                                        <h4>{{slide.name}}</h4>
                                        <span>{{slide.position}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='flaticon-next'></i>
                            </template>
                            <template #prev>
                                <i class='flaticon-left-arrow-1'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div> 
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                description: 'Mobile money is the future of finance and banking. In the Sub-Saharan African region, Mobile money is already gaining momentum and it has a scope of development further as there is a high adoption rate for using mobile money services rather than going with cash.',
                image: require('../../assets/images/testimonials/client-1.jpg'),
                name: 'Nikunj Gundaniya',
                position: 'Product manager, Digipay',
            },
            {
                id: 2,
                description: 'Financial technology, colloquially referred to as <<fintech>>, is accelerating financial inclusion in sub-Saharan Africa. The population of Africa will likely continue to utilize regions rising cellular and internet penetration and adopt emerging digital payment, banking, insurance, and lending services.',
                image: require('../../assets/images/testimonials/client-2.jpg'),
                name: 'Leo Holtz',
                position: 'Researcher',
            },
            {
                id: 3,
                description: 'Digital innovation has already begun to take off on the continent, with the number of active technology hubs doubling to more than 600 in three years by 2019. However, so far, the continent has made great strides in achieving success in rolling out what the industry calls Mobile Money 1.0, namely digital wallets that operate on a text-based interface.',
                image: require('../../assets/images/testimonials/client-3.jpg'),
                name: 'Paul Shumsky',
                position: 'Technology Advisor',
            },
            {
                id: 4,
                description: 'QR payments are ideal for the South African market. Local customers have shown they are eager to try new and more convenient payment methods, particularly if they are mobile-based and even more so if they are within their existing banking app. These truly contactless payments also make excellent business sense for banks and we have seen strong interest from financial service providers in South Africa and abroad,',
                image: require('../../assets/images/testimonials/client-4.jpg'),
                name: 'Jonathan van der Merwe',
                position: 'Product manager',
            },
            {
                id: 5,
                description: 'The use of QR codes for payment may still be in its onset stages in some countries now, but there are several reasons why more merchants will adopt QR more in the near future. ',
                image: require('../../assets/images/testimonials/client-5.jpg'),
                name: 'Brock Lee',
                position: 'Developer',
            },
        ],
    }),
})
</script>