<template>
    <div class="security-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="security-content">
                        <div class="content-title style1">
                            <span>Banking Security</span>
                            <h2>The Benefits of mobile payments</h2>
                            <p>Mobile payments can help you reduce expenses in a few ways. You do not need to buy expensive point of sale (POS) equipment or paper and ink due to the ability to email receipts. And because you can use a tablet or smartphone as your mobile point of sale terminal, the only external cost you will need to set it up is a card reader.</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <img src="../../assets/images/security/security-icon-1.png" alt="Image">
                                </div>
                                <div class="feature-text">
                                    <h3>Pay Online Securely</h3>
                                    <p>Mobile payment apps use an encrypted or protected code to shield customers personal data. Because of this, payment security is increased and your liability is significantly lower. </p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <div class="feature-icon">
                                    <img src="../../assets/images/security/security-icon-2.png" alt="Image">
                                </div>
                                <div class="feature-text">
                                    <h3>Convert Your Money In Seconds</h3>
                                    <p>Direct access to online currency exchange with accurate and competitive rate, allowing you to move your cash from one currency to another just in one click.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="security-img-wrap">
                        <img src="../../assets/images/security/security-shape-1.png" alt="Image" class="security-shape-one">
                        <img src="../../assets/images/security/security-shape-2.png" alt="Image" class="security-shape-two">
                        <img src="../../assets/images/security/security-1.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BankingSecurity'
}
</script>