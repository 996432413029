<template>
    <div class="feature-wrap pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-1.png" alt="Image"></span>
                                <h3>Create Your Account</h3>
                            </div>
                            <p>A mobile wallet is a digital way to store credit so that purchases can be made using a mobile phone. Enjoy the benefits offered by <strong>NetWallet</strong> with secure online banking, online payment and money transfer.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-2.png" alt="Image"></span>
                                <h3>Make Mobile Money Deposit</h3>
                            </div>
                            <p>Deposit money into your account using bank transfer or cash deposit and enjoy the benifit of reaching and proceeding any transactions online from shopping to paying the bills with online banking. </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-3.png" alt="Image"></span>
                                <h3>Pay Online</h3>
                            </div>
                            <p>Whether you need to collect contactless payments on the go, offer a wider variety of payment options, or cut down on in-store queues, mobile payments can help solve these problems.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>