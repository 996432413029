<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <img
        src="../../assets/images/footer-shape-1.png"
        alt="Image"
        class="footer-shape-one"
      />
      <img
        src="../../assets/images/footer-shape-2.png"
        alt="Image"
        class="footer-shape-two"
      />
      <div class="row pt-100 pb-75">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <img src="../../assets/images/logo.jpg" alt="Image" />
            </router-link>
            <p class="comp-desc">
                As Africa market grows, one of the main challenge remains a cross continental
                payment and collection system that will solve the issue of multiple currency on the continent. 
                Netwallet primary purpose is to address this issue by innovating in the area of internet banking.
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://www.facebook.com/Netwalletpay">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <!--<li>
                  <a target="_blank" href="https://twitter.com">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>-->
                <!--<li>
                  <a target="_blank" href="https://linkedin.com">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>-->
                <!--<li>
                  <a target="_blank" href="https://instagram.com">
                    <i class="ri-pinterest-fill"></i>
                  </a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Our Company</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  Company &amp; Team
                </router-link>
              </li>
              <li>
                <router-link to="/service-details">
                  <i class="flaticon-next"></i>
                  Our Services
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Privacy &amp; Policy
                </router-link>
              </li>

              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Contact Us
                </router-link>
              </li>

            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Services</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/service-details">
                  <i class="flaticon-next"></i>
                  Online Payment
                </router-link>
              </li>
              <li>
                <router-link to="/services">
                  <i class="flaticon-next"></i>
                  Security
                </router-link>
              </li>

              <li>
                <router-link to="/service-details">
                  <i class="flaticon-next"></i>
                  Receive Money
                </router-link>
              </li>
              <li>
                <router-link to="/service-details">
                  <i class="flaticon-next"></i>
                  Affiliate Program
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} Kolofa All Rights
        Reserved By
        <a href="https://netwalletpay.com/" target="_blank">Netwallet</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
