<template>
    <div class="contact-wrap pt-100">
        <div class="container">
            <div class="row justify-content-center pb-75">
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-map"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Our Location</h3>
                            <p>
                                Rue 1761
                                Derriere le palais des congres
                                PO BOX 20279 Yaounde-Cameroon
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-email-2"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Email Us</h3>
                            <a href="mailto:info@netwalletpay.com">info@netwalletpay.com</a>
                            <a href="mailto:support@netwalletpay.com">support@netwalletpay.com</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="contact-item">
                        <span class="contact-icon">
                            <i class="flaticon-phone-call"></i>
                        </span>
                        <div class="contact-info">
                            <h3>Call us</h3>
                            <a href="tel:237670709946">+237 670 72 99 46</a>
                            <a href="tel:237693842608">+237 699 09 72 34</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="comp-map pb-100">
                <iframe
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=palais de congres yaounde&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
            </div>
        </div>
        <div class="contact-form-area ptb-100 bg-albastor">
            <img src="../../assets/images/contact-shape-1.png" alt="Image" class="contact-shape-one animationFramesTwo">
            <img src="../../assets/images/contact-shape-2.png" alt="Image" class="contact-shape-two bounce">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
                        <div class="content-title style1 text-center mb-40">
                            <span>Send Us A Message</span>
                            <h2>Do You have Any Questions?</h2>
                        </div>
                        <div class="contact-form">
                            <div v-if="msg">
                                <p class="text-success">{{ msg }}</p>
                            </div>
                            <form class="form-wrap" @submit.prevent="sendRequest">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="name" v-model="name" placeholder="Your Name*" id="name"
                                                required data-error="Please enter your name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" v-model="email" required
                                                placeholder="Your Email*" data-error="Please enter your email*">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="number" name="phone" id="phone" v-model="phone" required
                                                placeholder="Phone Number" data-error="Please enter your phone number">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input type="text" name="msg_subject" v-model="msg_subject"
                                                placeholder="Subject" id="msg_subject" required
                                                data-error="Please enter your subject">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group v1">
                                            <textarea name="message" id="message" v-model="message"
                                                placeholder="Your Messages.." cols="30" rows="10" required
                                                data-error="Please enter your message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <button type="submit" class="btn style1 w-100 d-block">Send Message </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Contact',
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            msg_subject: '',
            message: '',
            msg: null,
        };
    },
    methods: {
        async sendRequest() {
            try {
                const response = await axios.post('/api/v1/contact/receive-message', {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    subject: this.msg_subject,
                    message: this.message,
                });
                this.msg = "We have received your message. Thanks for you contribution.";
            } catch (error) {
                console.error(error);
                const { message } = error.response.data;

                // Handle the login error
                this.msg = message || 'Unexpected error, please try again later';
            }
        },
    },
}
</script>