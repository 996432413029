<template>
  <div class="service-wrap ptb-100">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-1.png"
                alt="Image"
              />
            </span>
            <h3>
              <router-link to="/service-details"
                >Mobile Bankong</router-link
              >
            </h3>
            <p>
                NetWallet mobile payment reduces the need to visit banks for minuscule banking activities.
                You can access mobile banking services from any place, at any time.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-2.png"
                alt="Image"
              />
            </span>
            <h3>
                <router-link to="/service-details">Saving Account</router-link>
            </h3>
            <p>
                NetWallet Mobile Wallet savings account allows you to deposit money, keep it safe,
                and withdraw funds, all while earning interest. Open account now!
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-3.png"
                alt="Image"
              />
            </span>
            <h3>
              <router-link to="/service-details">Simple Dashboard</router-link>
            </h3>
            <p>
              Lorem ipsum dolor sit ametus situlo consec tetur adicingus
              elittque coris sapite illo sit.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-4.png"
                alt="Image"
              />
            </span>
            <h3>
                <router-link to="/service-details">Personal Savings</router-link>
            </h3>
            <p>
                Whether you are ready to start saving or are looking for options to maximize
                your earnings, NetWallet has the right solution for your needs.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-5.png"
                alt="Image"
              />
            </span>
            <h3>
              <router-link to="/service-details"
                >Secured Transaction</router-link
              >
            </h3>
            <p>
                Make payment in a safe, fast, and secure way to your recipient within 
                Netwallet and to popular payment systems in Africa.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="service-card style1">
            <span class="service-icon">
              <img
                src="../../assets/images/service/service-icon-6.png"
                alt="Image"
              />
            </span>
            <h3>
              <router-link to="/service-details">Verified Account</router-link>
            </h3>
            <p>
              Lorem ipsum dolor sit ametus situlo consec tetur adicingus
              elittque coris sapite illo sit.
            </p>
          </div>
        </div>
      </div>
      <ul class="page-nav list-style mt-20">
        <li>
          <a href="/services"><i class="flaticon-back"></i></a>
        </li>
        <li><a class="active" href="/services">1</a></li>
        <li><a href="/services">2</a></li>
        <li><a href="/services">3</a></li>
        <li>
          <a href="/services"><i class="flaticon-next-1"></i></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
