<template>
    <div class="blog-details-wrap ptb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2">
                    <BlogSidebar />
                </div>
                <div class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1">
                    <div>
                        <div class="post-img">
                            <img src="../../assets/images/blog/single-blog-1.jpg" alt="Image">
                        </div>
                        
                        <h2>Why QR codes are becoming omnipresent in the digital payment industry</h2>
                        <div class="post-para">
                            <p>The world of digital payments is changing. People are shifting towards simple and automatic payment processes. 
                                 The QR payment technology is making lives easy for customers, businesses, banks, and financial institutes. 
                                 You can find a QR code everywhere you look. It is at restaurants, airports, tickets, documents, and much more.
                            </p>
                            <h5>Enhancing the Customer Experience</h5>
                            <p>
                                Advancements in QR codes can enhance the customers' experience of digital payments by making it a touchless and seamless experience for their customers. 
                                The ATM habits of customers have started changing with the introduction of QR codes for ATMs.
                            </p>
                            <p>
                                QR codes are the best digital payment option to improve customer experience in the banking industry. 
                                It is creating a truly touchless experience for the customers by integrating ATM with the use of API tech and microservices. 
                                This has the potential to essentially transform the QR payment landscape.
                            </p>
                            <blockquote class="wp-block-quote">
                                <span class="wp-quote-icon"><i class="flaticon-quotation-mark"></i></span>
                                <div class="wp-quote-text">
                                    <p>"Product manager of <strong>Netwalletpay</strong> South East Asia, one of the leading digital wallet solution. He is a visionary leader whose flamboyant management style has given profitable results for the company. He believes in the mantra of giving 100% to his work.</p>
                                    <h6>Mal Practive</h6>
                                </div>
                            </blockquote>
                            
                            <h5>Easy Usage</h5>
                            <ol>
                                <li>Many small vendors were hesitant to start using the QR codes and wallets at first. But during the covid time, people were afraid to make payments in cash. So, various vendors started doing contactless payments during covid.</li>
                                <li>This influenced other vendors too and they followed the trend. Now even a small tea stall around the corner of the street has QR codes.</li>
                                <li>It is free and simple to use for all shop owners. All they have to do is just paste the code in an area visible to everyone, and keep a check on their phone after their customers make the payments and everything is done.</li>
                            </ol>
                            <h5>Acquiring Customers</h5>
                            <p>Since digital payments became common, not everyone carries cash all the time. So, when the small vendors set up the QR codes for their store or shop, they make their way to every customer with cash as well as digital payment options on their smartphones.</p>
                            <p>
                                Be it a small stall, a shop, or a store, with a QR code feature, the customer footfall increases a lot. 
                                For example, an ice cream stall owner with a QR code option will increase sales because of the multiple payment modes available. 
                                The QR codes can support multiple financial institutes and wallet apps.
                            </p>
                            
                            <h5>Seamless Payments and Transactions</h5>

                            <p>A few years back, QR Code payments were not the payment method that was preferred due to slow internet, minimal usage of smartphones, and overall usage. However, now the scenario has changed. The future of digital payments is contactless. QR codes are making it possible.</p>
                                
                            <p>Be it online transactions, paying bills, or processing international payments, QR codes make it simple and easy. You can create a unique QR code or a code for a POS terminal for your customers, for online payments. This makes the payments quick and effortless.</p>
                        </div>
                    </div>
                    <div class="post-meta-option">
                        <div class="row gx-0 align-items-center">
                            <div class="col-md-7 col-12">
                                <div class="post-tag">
                                    <span> <i class="flaticon-supermarket"></i>Tags:</span>
                                    <ul class="tag-list list-style">
                                        <li><router-link to="#">QR code</router-link>,</li>
                                        <li><router-link to="#">cashless payment</router-link>,</li>
                                        <li><router-link to="#">mobile wallet</router-link></li>
                                    </ul>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogSidebar from '../Common/BlogSidebar'

export default {
    name: 'BlogDetailsLeftSidebar',
    components: {
        BlogSidebar
    }
}
</script>