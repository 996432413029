<template>
    <div class="faq-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="faq-img-wrap">
                        <img src="../../assets/images/faq-1.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="accordion style2" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="true" aria-controls="collapseOne">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    What Is Bank Account?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p>It is a long established fact that a reader will be distracted by the readable conten of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less no content here', making it look like readable English.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    How Do I Activate My Card?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse "
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>It is a long established fact that a reader will be distracted by the readable conten of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less no content here', making it look like readable English.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                    aria-expanded="false" aria-controls="collapseThree">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    How Do I Change My Passwoed?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>It is a long established fact that a reader will be distracted by the readable conten of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less no content here', making it look like readable English.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingfour">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapsefour"
                                    aria-expanded="true" aria-controls="collapsefour">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    How To Successfully Changed My Acount Name?
                                </button>
                            </h2>
                            <div id="collapsefour" class="accordion-collapse collapse "
                                aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p>Lorem ipsum dolor sit amet consec tetur adipisicing elit. Quisquam sit laborum est aliquam. Dicta fuga soluta eius exercitationem porro modi. Exercitationem eveniet aliquam repudiandae sequi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingfive">
                                <button class="accordion-button collapsed" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapsefive"
                                    aria-expanded="true" aria-controls="collapsefive">
                                    <span>
                                        <i class="ri-arrow-down-s-line plus"></i>
                                        <i class="ri-arrow-up-s-line minus"></i>
                                    </span>
                                    What Are The Requirements For Business Loan?
                                </button>
                            </h2>
                            <div id="collapsefive" class="accordion-collapse collapse "
                                aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="single-product-text">
                                        <p>Lorem ipsum dolor sit amet consec tetur adipisicing elit. Quisquam sit laborum est aliquam. Dicta fuga soluta eius exercitationem porro modi. Exercitationem eveniet aliquam repudiandae sequi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Faq'
}
</script>