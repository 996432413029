<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        <h3>Information Collection</h3>
                        <p>
                            This privacy policy sets out how Netwalletpay Limited (hereon referred to as Netwallet) uses and protects any information that you give Netwallet when you use this website. This is important; we hope you will take time to read it carefully.
                        </p>
                        <p>
                            Netwallet is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website or performing transactions, then you can be assured that it will only be used in accordance with this privacy statement.
                        </p>
                    </div>
                    <div class="single-terms">

                        <p>Netwallet may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
                        <h3>We may collect the following information:</h3>
                        <ul class="content-feature-list list-style mb-0">
                            <li><i class="ri-checkbox-multiple-line"></i>Your Name.</li>
                            <li>
                                <i class="ri-checkbox-multiple-line"></i>Your contact information including email address.
                            </li>
                            <li><i class="ri-checkbox-multiple-line"></i>Business name, type, address, email and phone number.</li>
                            <li>
                                <i class="ri-checkbox-multiple-line"></i>Registered & Certified Certificate of Incorporation.
                            </li>
                            <li>
                                <i class="ri-checkbox-multiple-line"></i>CBrief company profile including the website details.
                            </li>
                            <li>
                                <i class="ri-checkbox-multiple-line"></i>Identity Documents of Primary and related parties (National ID (for nationals) OR Passport (for Foreigners) OR Refugee ID and Refugee attestations (for refugees).
                            </li>
                            <li>
                                <i class="ri-checkbox-multiple-line"></i>Trading License.
                            </li>
                            <li><i class="ri-checkbox-multiple-line"></i>Other information relevant to customer surveys and/or offers.</li>
                        </ul>
                    </div>

                    <div class="single-terms">

                        <h3>Disclosures</h3>
                        <p>
                            We will not without your consent disclose to third parties any individually identifying information,
                            such as names, postal and e-mail addresses, telephone numbers, or others, provided to Netwallet.
                        </p>
                        <p>
                            The rendering of our services to our companyʼs perspective and/or existing clients requires the
                            disclosure of personal data related to our prospective and/or existing clients to third parties.
                            These third parties are external service providers, which are legally and economically independent
                            from Netwallet and have third-party agreements in place with Netwallet, whereas all of our
                            subcontractors and/or external service partners are bound to our standard on Data Protection.
                            Prior to processing personal data related to our clients, Netwallet will inform the specifific client
                            about that use and asks for the explicit consent of that person accordingly.
                        </p>
                        
                        <p>
                            We may also disclose your personal information to third parties if we are under a duty to disclose
                            or share your personal data in order to comply with any legal obligation or in order to enforce or
                            apply our Site Terms and Conditions. Furthermore, your personal data is permitted to be disclosed
                            to public bodies only in cases where the disclosure concerns matters of national and/or
                            international security, the prevention or investigation of criminal activities, law enforcement,
                            where the public interest is given, a juridical proceeding against the person, to which the personal
                            data is related is pending or had been initiated or completed before and/or the personal security
                            of the individual to which the personal data is related.
                        </p>
                        <p>
                            If you click on one of the social media links on Netwalletʼs Website or otherwise interact with
                            Netwalletʼs social media pages such of third parties, Netwallet and the relevant social media platform
                            may receive information relating to such interaction and may share your personal data in
                            connection with this purpose, such as certain Behavioural Data and Technical Data.
                        </p>
                        <p>
                            The relevant social media platform may also be a controller and/or joint controller in respect of
                            the personal data that is collected via the use of our social media pages and may use that
                            personal data for additional purposes. For details on how the relevant social media platform uses
                            your personal data, please see the privacy policy of the relevant social media platform.
                        </p>
                        <p>
                            Netwallet will communicate the specifific purpose for processing your personal data to you prior to
                            its use as described in this privacy policy.
                        </p>
                        
                    </div>
                    <div class="single-terms">
                        <ol>
                            <li>Internal record keeping.</li>
                            <li>We may use the information to improve our products and services.</li>
                            <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                            <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone or fax.</li>
                            <li>We may use the information to customize the website according to your interests.</li>
                        </ol>
                    </div>
                    
                    <div class="single-terms">
                        <h3>Anti-Money Laundering Monitoring</h3>
                        <ol>
                            <li>Kolofa has taken measures to identify customers who maintain accounts with Netwallet to ensure that they maintain such accounts in the true name of the account holder, and do not open or keep anonymous accounts or accounts which are in fictitious or incorrect names.</li>
                            
                            <li>Netwallet shall not initiate a business relationship or carry out an occasional transaction, including the opening of a new account or entering into a fiduciary transaction, 
                                performing a cash transaction over a capped amount, without undertaking customer due diligence measures, including recording and verifying by reliable means the identity of the client, 
                                including their true name as provided by the authorised identification document. Subject to our Privacy Policy, the information you give to us may enable us access your address including postal and residential, 
                                employment, and occupation and this may also apply to your representative or whoever has been appointed to act on your behalf.</li>

                            <li>Netwallet also undertakes further customer due diligence measures to verify the Netwallet customer’s identity using reliable, independent source documents, 
                                data or information, such as passports, birth certificates, driver’s licenses, identity cards, voter’s card, utility bills, bank statements, 
                                partnership contracts and incorporation documents or other identification documents prescribed by regulations made under the law.</li>

                            <li>Alongside this, we shall verify the beneficial owner of the account and take reasonable measures to understand the ownership, control and structure of the customer obtaining information. 
                                This shall include conducting on going due diligence on the business relationship and where necessary the source, purpose and destination of the funds.</li>
                            
                            <li>Netwallet shall verify the identity of the customer and beneficial owner before or during the course of opening a Netwallet account or conducting a transaction through 
                                Netwallet and this shall apply to each of our customers although the extent may be dependent on the risk sensitiveness of each customer so that for higher risk classified customers, 
                                we shall perform an enhanced due diligence.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>